import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import DownloadButton from '../components/DownloadButton';
import Layout from '../components/layout';
import Seo from '../components/seo';

const MdxPage = ({ data }) => {
  const heroImage = data.mdx.frontmatter.featured_image
    ? getImage(data.mdx.frontmatter.featured_image)
    : null;
  const open_graph_image = data.mdx.frontmatter.open_graph_image
    ? data.mdx.frontmatter.open_graph_image.childImageSharp.resize
    : null;
  const pageTitle = data.mdx.frontmatter.title
    ? data.mdx.frontmatter.title
    : null;
  const heroImageCredit = data.mdx.frontmatter.featured_image_credit
    ? data.mdx.frontmatter.featured_image_credit
    : null;
  const pageSubTitle =
    'Get art like this on your wallpaper every day! Download Art Diario';
  return (
    <Layout>
      <Seo title={pageTitle} image={open_graph_image} />
      <div className="relative pb-16">
        <div className="text-lg mx-auto">
          <h1 className="text-2xl sm:text-4xl text-center leading-10 text-slate-400 font-extrabold mb-0 sm:mb-10 max-w-2xl mx-auto">
            {pageTitle}
          </h1>
          <h3 className="text-xl sm:text-xl text-center leading-10 text-slate-600 font-bold mb-5 sm:mb-10 max-w-xl mx-auto">
            {pageSubTitle}
          </h3>
          <DownloadButton />
          <div className="mt-4 sm:mt-12">
            <div
              className="my-10 drop-shadow-art min-h-[25rem] will-change-transform"
              data-sal-duration="300"
              data-sal="fade"
              data-sal-delay="1000"
              data-sal-easing="ease-in-out"
            >
              <GatsbyImage
                image={heroImage}
                alt={heroImageCredit}
                className="object-cover"
              />
            </div>
          </div>
          {data.mdx.frontmatter.featured_image_credit_link ? (
            <p className="mt-4 text-center text-base text-gray-400">
              <a
                href={data.mdx.frontmatter.featured_image_credit_link}
                className="underline"
              >
                {data.mdx.frontmatter.featured_image_credit}
              </a>
            </p>
          ) : (
            ''
          )}
          <div className="mt-6 sm:mt-12 prose-gray prose prose-xl mx-auto prose-a:text-blue-600 hover:prose-a:text-blue-500">
            <MDXProvider>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      body
      slug
      frontmatter {
        title
        featured_image_credit
        featured_image_credit_link
        featured_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        open_graph_image: featured_image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default MdxPage;
