import * as React from 'react';
import { Link } from 'gatsby';

const Header = () => {
  return (
    <>
      <h1 className="text-2xl font-extrabold mx-auto text-center py-10 border-b border-slate-200 w-9/12">
        <Link to="/">Art Diario</Link>
      </h1>
    </>
  );
};

export default Header;
